import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Grid from "@material-ui/core/Grid";

const styles = () => ({
  root: {
    position: "relative"
  },
  top: {
    color: "#eef3fd",
    animationDuration: "550ms",
    position: "absolute"
  },
  bottom: {
    color: "#6798e5",
    animationDuration: "550ms",
    position: "absolute"
  }
});

class FacebookProgress extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.props.text}
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.top}
          size={15}
          thickness={5}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.bottom}
          size={15}
          thickness={5}
        />
      </div>
    );
  }
}

export default withStyles(styles)(FacebookProgress);
