import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import Loader from "../loader";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 0,
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3
      }px`,
  },
});

class CheckInstall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ESN: "4661429667",
      ESN: this.props.ESN,
      // ICCID: "8944502305189403626",
      ICCID: this.props.Iccid,
      custCode: this.props.custCode,
      database: this.props.database,
      // custCode: "VXDEV",
      // database: "vx_dev",
      // custCode: "MIDPAC",
      // database: "vx_midpac",
      error: "",
      foundEvent: "",
      showIgnitionOn: false,
      showIgnitionOff: false,
    };
  }
  //  http://activate.trackingsolutions.io:5000/api/
  chooseOnURL(length) {
    if (length === 10) {
      return "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOnforAU/";
    } else if (length === 6) {
      return "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOnforAuTS62/";
    }
  }

  chooseOffURL(length) {
    if (length === 10) {
      return "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOffforAU/";
    } else if (length === 6) {
      return "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOffforAuTS62/";
    }
  }

  getOnLat(length, data) {
    if (length === 10) {
      return data.LATITUDE ? data.LATITUDE : "";
    } else if (length === 6) {
      return data.latitude ? data.latitude : "";
    } else {
      return "";
    }
  }

  getOnLong(length, data) {
    if (length === 10) {
      return data.LONGITUDE ? data.LONGITUDE : "";
    } else if (length === 6) {
      return data.longitude ? data.longitude : "";
    } else {
      return "";
    }
  }
  componentDidMount() {
    console.log(this.props.ESN);
    console.log(this.props.ESN.length);
    console.log(this.props.database);
    let ONURL = this.chooseOnURL(this.props.ESN.length);
    let OFFURL = this.chooseOffURL(this.props.ESN.length);
    console.log(ONURL);
    console.log(OFFURL);
    this.setState({
      showIgnitionOn: true,
      error: "",
      foundEvent: "",
    });
    axios({
      method: "get",
      url: ONURL + this.state.ESN + "/" + this.state.database,
    }).then(async (response) => {
      console.log("in responce");
      if (response.data) {
        console.log("got responce.data.ON");
        console.log(response.data);

        if (response.data.data !== "Time Out") {
          // Found ON
          let onLat = this.getOnLat(this.props.ESN.length, response.data.data);
          let onLong = this.getOnLong(
            this.props.ESN.length,
            response.data.data
          );
          console.log(onLat);
          console.log(onLong);

          console.log("Found ON");
          console.log(response.data.data);
          console.log(response.data.data.LATITUDE);
          this.setState({
            showIgnitionOn: false,
            showIgnitionOff: true,
            error: onLat ? false : true,
            foundEvent: "",
            network: "OK",
            hourMeterTracking: "OK",
            GPS: onLat ? "OK" : "Not tracking",
            latitude: onLat ? onLat : "",
            longitude: onLong ? onLong : "",
          });
          this.props.callbackCheckInstall(
            this.state.network,
            this.state.hourMeterTracking,
            this.state.GPS,
            this.state.latitude,
            this.state.longitude,
            this.state.error
          );
          axios({
            method: "get",
            url: OFFURL + this.state.ESN + "/" + this.state.database,
          }).then(async (response) => {
            console.log("in responce OFF");
            if (response.data) {
              console.log("got responce OFF");
              if (response.data.data !== "Time Out") {
                let offLat = this.getOnLat(
                  this.props.ESN.length,
                  response.data.data
                );
                let offLong = this.getOnLong(
                  this.props.ESN.length,
                  response.data.data
                );

                console.log("got responce.data.off");
                // Found OFF
                console.log("found");
                this.setState({
                  network: "OK",
                  hourMeterTracking: "OK",
                  GPS: offLat ? "OK" : "Not tracking",
                  latitude: offLat ? offLat : "",
                  longitude: offLong ? offLong : "",
                  error: offLong ? false : true,
                });
                this.props.callbackCheckInstall(
                  this.state.network,
                  this.state.hourMeterTracking,
                  this.state.GPS,
                  this.state.latitude,
                  this.state.longitude,
                  this.state.error
                );
                this.props.goToNextStep();
              } else if (response.data.data === "Time Out") {
                console.log("not Found Off");

                // Not Found OFF
                this.setState({
                  error: true,
                  showIgnitionOn: false,
                  network: "Not OK",
                  hourMeterTracking: "Not OK",
                });
                this.props.callbackCheckInstall(
                  this.state.network,
                  this.state.hourMeterTracking,
                  this.state.GPS,
                  this.state.latitude,
                  this.state.longitude,
                  this.state.error
                );
                this.props.goToNextStep();
              }
            } else {
              console.log("not Found responce.data.off");

              // Not Found OFF
              this.setState({
                error: true,
                showIgnitionOn: false,
                network: "Not OK",
                hourMeterTracking: "Not OK",
              });
              this.props.callbackCheckInstall(
                this.state.network,
                this.state.hourMeterTracking,
                this.state.GPS,
                this.state.latitude,
                this.state.longitude,
                this.state.error
              );
              this.props.goToNextStep();
            }
          });
        } else if (response.data.data === "Time Out") {
          console.log("Not Found ON");

          // Not found ON
          this.setState({
            network: "Not found",
            hourMeterTracking: "Not tracking",
            // GPS: response.data.data.latitude ? "OK" : "Not tracking",
            // latitude: response.data.data.latitude
            //   ? response.data.data.latitude
            //   : "",
            // longitude: response.data.data.longitude
            //   ? response.data.data.longitude
            //   : "",
            GPS: "Not tracking",
            latitude: "",
            longitude: "",
            error: true,
            showIgnitionOn: false,
          });
          console.log("not found");
          this.props.callbackCheckInstall(
            this.state.network,
            this.state.hourMeterTracking,
            this.state.GPS,
            this.state.latitude,
            this.state.longitude,
            this.state.error
          );
          this.props.goToNextStep();
        }
      } else {
        console.log("Not Found responce.data.ON");

        // Not Found OFF
        this.setState({
          network: "Not found",
          hourMeterTracking: "Not tracking",
          // GPS: response.data.data.latitude ? "OK" : "Not tracking",
          // latitude: response.data.data.latitude
          //   ? response.data.data.latitude
          //   : "",
          // longitude: response.data.data.longitude
          //   ? response.data.data.longitude
          //   : "",
          GPS: "Not tracking",
          latitude: "",
          longitude: "",
          error: true,
          showIgnitionOn: false,
        });
        console.log("not found");
        this.props.callbackCheckInstall(
          this.state.network,
          this.state.hourMeterTracking,
          this.state.GPS,
          this.state.latitude,
          this.state.longitude,
          this.state.error
        );
        this.props.goToNextStep();
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          {this.state.showIgnitionOn && (
            <div>
              <Typography variant="">
                Please turn <b style={{ color: "Green" }}>ON</b> the vehicle.
              </Typography>
              <Typography variant="body2">Looking for events...</Typography>
              <Loader />
              <br />
              <br />
            </div>
          )}

          {this.state.showIgnitionOff && (
            <div>
              <Typography variant="">
                Now, turn <b style={{ color: "Red" }}>OFF</b> the vehicle.
              </Typography>
              <Typography variant="body2">Checking for events...</Typography>
              <Loader />
              <br />
            </div>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CheckInstall);
