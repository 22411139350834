import React, { Component } from "react";
import logo from "./img/TrackingSolutionsLogo.png";

class Layout extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            // background: "White",
            width: "100%",
            textAlign: "center",
            color: "Black",
            paddingTop: "40px",
            marginLeft: "-9px",
          }}
        >
          <img
            src={logo}
            style={{
              height: 70,
            }}
            alt={"TS Logo"}
          />
        </div>
      </div>
    );
  }
}

export default Layout;
