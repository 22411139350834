import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import axios from "axios";

const styles = (theme) => ({
  layout: {
    width: "100%",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 0,
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3
      }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: "#fff",
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  imageAlign: {
    textAlign: "center",
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class EnterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: "paper",
      numPages: null,
      errorTextEsn: "",
      errorTextsSerialNumber: "",

      ESN: localStorage.getItem("localESN")
        ? localStorage.getItem("localESN")
        : "",
      // : "4661424718",
      serialNumber: localStorage.getItem("localSerialNumber")
        ? localStorage.getItem("localSerialNumber")
        : "",
      Name: localStorage.getItem("localName")
        ? localStorage.getItem("localName")
        : "-",
      hourMeter: localStorage.getItem("localHourMeter")
        ? localStorage.getItem("localHourMeter")
        : "-",
      verify: "",
      custCode: "",
      dataBase: "",
    };
  }
  onChangeESN(esn) {
    localStorage.setItem("localESN", esn.target.value);
    this.setState({ ESN: esn.target.value });
  }
  onChangeSerialNumber(serialNumber) {
    localStorage.setItem("localSerialNumber", serialNumber.target.value);
    this.setState({ serialNumber: serialNumber.target.value });
  }
  onChangeName(ename) {
    localStorage.setItem("localName", ename.target.value);
    this.setState({ Name: ename.target.value });
  }
  onChangeHourMeter(localHourMeter) {
    localStorage.setItem("localHourMeter", localHourMeter.target.value);
    this.setState({ hourMeter: localHourMeter.target.value });
  }

  checkEsnInPuls = (esn) => {
    console.log("here 2");
    axios({
      method: "get",
      url: "http://activate.trackingsolutions.io:5000/api/CheckESN/" + esn,
    }).then(async (response) => {
      console.log(response.data.data.message);
      if (response.data.data.message) {
        console.log("H23");
        this.setState({
          errorTextEsn: "Device not found - please contact your administrator",
          verify: "",
        });
      } else {
        console.log(response.data.data.group);

        if (response.data.data.group) {
          let vx = "vx_";
          var custCodeArray = response.data.data.group.split("-");
          if (custCodeArray[0].length === 5) {
            console.log(custCodeArray[1].toLowerCase());
            console.log("Add the device");
            this.setState({
              dataBase: "vx_".concat(custCodeArray[1].toLowerCase()),
              custCode: custCodeArray[1],
            });
            console.log("database:" + this.state.dataBase);

            // Add device in table
            axios({
              url:
                "http://activate.trackingsolutions.io:5000/api/addToDeviceDatabaseLookup/" +
                esn +
                "/" +
                this.state.dataBase,
            }).then(async (response) => {
              console.log("response.data");
              if (response.data.data) {
                console.log(response.data);
                if (this.checkSerialNumber(this.state.serialNumber) == "Pass") {
                  console.log(this.state.ESN);
                  console.log(this.state.serialNumber);
                  this.props.callbackEnterDetails(
                    this.state.ESN,
                    this.state.serialNumber,
                    this.state.dataBase,
                    this.state.Name,
                    this.state.hourMeter
                  );
                  this.props.goToNextStep();
                }
              } else if (response.data.Error) {
                console.log("here6");
                this.setState({
                  errorTextEsn:
                    "Device not found - please contact your administrator",
                  verify: "",
                });
              }
            });
          } else {
            console.log("here9")
            this.setState({
              errorTextEsn:
                "Device not found - please contact your administrator",
              verify: "",
            });
          }
        }
      }
    });
  };

  checkSerialNumber = (srnumber) => {
    if (srnumber.length === 0) {
      this.setState({
        errorTextsSerialNumber: "A serial number for equipment is required",
        verify: "",
      });
    } else {
      this.setState({ verify: "Verified" });
      this.setState({
        errorTextsSerialNumber: "",
        errorTextEsn: "",
      });
      return "Pass";
    }
  };

  handleOnClickVerification() {
    //-------------------------------------------------------------------ESN Validation-------------------------------------------------------------------//
    this.setState({ verify: "Verifying..." });

    if (this.state.ESN.length === 0) {
      this.setState({
        errorTextEsn: "Serial number of device is required",
        verify: "",
      });
    } else if (this.state.ESN) {
      // axios({
      //   method: "get",
      //   url:
      //     "http://activate.trackingsolutions.io:5000/api/CheckESN/" +
      //     this.state.ESN,
      // }).then(async (response) => {

      // console.log(response.data.data.message);
      // if (response.data.data.message) {
      // this.setState({
      //   errorTextEsn:
      //     "Device not found - please contact your administrator",
      //   verify: "",
      // });
      // } else {
      // console.log(response.data.data.group);

      // if (response.data.data.group) {
      //   var custCodeArray = response.data.data.group.split("-");
      //   if (custCodeArray[0].length === 5) {
      //     console.log(custCodeArray[1].toLowerCase());
      //   } else {
      //     this.setState({
      //       errorTextEsn:
      //         "Device not found - please contact your administrator",
      //       verify: "",
      //     });
      //   }
      // }

      axios({
        method: "get",
        url:
          "http://activate.trackingsolutions.io:5000/api/getDatabase/" +
          this.state.ESN,
      }).then(async (response) => {
        if (response.data.error) {
          this.checkEsnInPuls(this.state.ESN);
          // this.setState({
          //   errorTextEsn:
          //     "Device not found - please contact your administrator.",
          //   verify: "",
          // });
        } else if (response.data.data) {
          console.log(response.data.data[0]);
          console.log(response.data.data[0].databaseName);
          console.log("This one");
          console.log(response.data.data[0].CustCode);
          this.setState({
            dataBase: response.data.data[0].databaseName,
            custCode: response.data.data[0].CustCode
          });

          if (this.checkSerialNumber(this.state.serialNumber) == "Pass") {
            console.log(this.state.ESN);
            console.log(this.state.serialNumber);
            let emailName = this.state.Name ? this.state.Name : '-';
            let emailHourMeter = this.state.hourMeter ? this.state.hourMeter : '-';
            axios({
              method: "get",
              url:
                "http://activate.trackingsolutions.io:5000/api/SendSupportEmail/" +
                this.state.ESN +
                "/" +
                this.state.serialNumber +
                "/" +
                this.state.custCode +
                "/" +
                emailName +
                "/" +
                emailHourMeter,
            }).then(async (response) => {
              console.log("sent email");
            });
            axios({
              method: "get",
              url:
                "http://activate.trackingsolutions.io:5000/api/CheckVXDatabase/" +
                this.state.ESN +
                "/" +
                this.state.dataBase,
            }).then(async (response) => {
              if (response.data.error) {
                console.log("Here10");
                this.setState({
                  errorTextEsn:
                    "Device not found - please contact your administrator.",
                  verify: "",
                });
              } else if (response.data.data) {
                if (this.state.serialNumber.length === 0) {
                  this.setState({
                    errorTextsSerialNumber:
                      "A serial number for equipment is required",
                    verify: "",
                  });
                } else {
                  if (response.data.data[0].aadserialno) {
                    console.log("Serial no present");
                    if (
                      response.data.data[0].aadserialno ===
                      this.state.serialNumber
                    ) {
                      console.log("Serial numbers are same");
                    } else if (
                      response.data.data[0].aadserialno !==
                      this.state.serialNumber
                    ) {
                      console.log("Serial numbers are Different");
                      // axios({
                      //   method: "get",
                      //   url:
                      //     "http://activate.trackingsolutions.io:5000/api/SendSupportEmail/" +
                      //     this.state.ESN +
                      //     "/" +
                      //     this.state.serialNumber +
                      //     "/" +
                      //     response.data.data[0].aadserialno,
                      // }).then(async (response) => {
                      //   console.log("Added Serial number");
                      // });
                    }
                  } else if (!response.data.data[0].aadserialno) {
                    console.log("Serial number not present");
                    //Add serial no to VX database
                    // axios({
                    //   method: "get",
                    //   url:
                    //     "http://activate.trackingsolutions.io:5000/api/addSerialNumber/" +
                    //     this.state.ESN +
                    //     "/" +
                    //     this.state.serialNumber +
                    //     "/" +
                    //     this.state.dataBase,
                    // }).then(async (response) => {
                    //   console.log("Added Serial number");
                    // });
                  }
                  console.log(response.data.data);
                  this.setState({
                    errorTextsSerialNumber: "",
                    errorTextEsn: "",
                  });

                  console.log(this.state.ESN);
                  console.log(this.state.serialNumber);
                }
              }
            });
            this.props.callbackEnterDetails(
              this.state.ESN,
              this.state.serialNumber,
              this.state.dataBase,
              this.state.Name,
              this.state.hourMeter,
              this.state.custCode
            );
            this.props.goToNextStep();
          }
        }
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="">
              Please enter the following details:
            </Typography>
            <form className={classes.form}>
              <div>
                <Grid container spacing={0} alignItems="flex-end">
                  <Grid item xs={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        required
                        id="esn"
                        name="esn"
                        label="Serial number of the hardware device."
                        type="number"
                        defaultValue={this.state.ESN}
                        fullWidth
                        autoComplete="off"
                        error={
                          this.state.errorTextEsn.length === 0 ? false : true
                        }
                        helperText={this.state.errorTextEsn}
                        onChange={this.onChangeESN.bind(this)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <FormControl margin="normal" fullWidth>
                  <TextField
                    required
                    id="serialnumber"
                    name="serialnumber"
                    label="Serial number of the equipment you installed the unit into."
                    type="text"
                    defaultValue={this.state.serialNumber}
                    fullWidth
                    autoComplete="off"
                    error={
                      this.state.errorTextsSerialNumber.length === 0
                        ? false
                        : true
                    }
                    helperText={this.state.errorTextsSerialNumber}
                    onChange={this.onChangeSerialNumber.bind(this)}
                  />
                </FormControl>

                <FormControl margin="normal" fullWidth>
                  <TextField
                    id="vehicleName"
                    name="vehicleName"
                    label="Equipment name"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    defaultValue={this.state.Name == '-' ? '' : this.state.Name}
                    onChange={this.onChangeName.bind(this)}
                  />
                </FormControl>

                <FormControl margin="normal" fullWidth>
                  <TextField
                    id="hourMeter"
                    name="hourMeter"
                    label="Hour meter reading"
                    type="number"
                    fullWidth
                    autoComplete="off"
                    defaultValue={this.state.hourMeter}
                    onChange={this.onChangeHourMeter.bind(this)}
                  />
                </FormControl>

                <br />
                <br />
                <div style={{ textAlign: "center" }}>
                  <Typography>{this.state.verify}</Typography>
                </div>
              </div>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
EnterDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EnterDetails);
