import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

class footer extends Component {
  render() {
    var d = new Date();
    var a = d.getFullYear();
    return (
      <div>
        <div
          style={{
            // background: "White",
            bottom: "15px",
            textAlign: "center",
            width: "100%",
            color: "gray",
            paddingTop: "40px",
          }}
        >
          <Typography variant="body" display="block" gutterBottom>
            © {a} by Tracking Solutions. All rights reserved
          </Typography>
        </div>
      </div>
    );
  }
}

export default footer;
