import React, { Component } from "react";
import Layout from "./layout";
import Footer from "./footer";
import withStyles from "@material-ui/core/styles/withStyles";
import { BrowserRouter as Router, Route } from "react-router-dom";
import USApp from "../src/USApp";
import AUApp from "../src/AUApp";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
const styles = (theme) => ({
  root: {
    width: "50%",
    display: "block",
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  root2: {
    width: "100%",
    display: "block",
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  // button: {
  //   "& > *": {
  //     margin: theme.spacing(1),
  //   },
  // },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "white",
    backgroundColor: "#F25B08",
    "&:hover": {
      backgroundColor: "#F25B08",
    },
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideButtons: false,
    };
  }

  render() {
    const { classes } = this.props;
    console.log(window.location.href);
    if (window.location.href === "http://activate.trackingsolutions.io/") {
      return (
        <div style={{ background: "white" }}>
          <Layout />
          <div
            style={{ textAlign: "center", paddingTop: 40, paddingBottom: 10 }}
          >
            <Typography variant="">Please Select Your Region</Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              href="/au"
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ hideButtons: false });
              }}
              style={{
                margin: 10,
                minWidth: "150px",
              }}
              className={classes.button}
            >
              Australia
            </Button>
            <Button
              href="/us"
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ hideButtons: false });
              }}
              style={{ margin: 10, minWidth: "150px" }}
              className={classes.button}
            >
              USA
            </Button>
          </div>
          <Footer />
        </div>
      );
    } else {
      return (
        <div style={{ background: "white" }}>
          <Layout />
          <Router>
            <Route path="/us" component={USApp} />
            <Route path="/au" component={AUApp} />
          </Router>

          <Footer />
        </div>
      );
    }
  }
}

export default withStyles(styles)(App);
