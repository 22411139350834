import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import axios from "axios";
import Loader from "../loader";

// activate.trackingsolutions.io
// activate.trackingsolutions.io
const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 0,
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "white",
    backgroundColor: "#F25B08",
    "&:hover": {
      backgroundColor: "#F25B08",
    },
  },
});

class Activation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ESN: "4661424718",
      ESN: this.props.ESN,
      //ICCID: "8944502305189403626",
      ICCID: this.props.ICCID,
      custCode: this.props.custCode,
      database: this.props.database,
      // custCode: "VXDEV",
      // database: "vx_dev",
      // custCode: "MIDPAC",
      // database: "vx_midpac",
      Name: this.props.Name,
      eName: this.props.eName,
      Make: this.props.Make,
      Model: this.props.Model,
      serialNumber: this.props.serialNumber,
      serviceInterval: this.props.serviceInterval,
      lastServiceDate:
        this.props.lastServiceDate === ""
          ? "0000-00-00"
          : this.props.lastServiceDate,
      hourMeter: this.props.hourMeter,

      // sms1: "!R3,1,51",
      // sms2: "!R3,1,51",
      // sms3: "!R3,1,51",
      // sms4: "!R3,1,51",
      // sms5: "!R3,1,51",
      // sms6: "!R3,1,51",
      // sms7: "!R3,1,51",
      // sms8: "!R3,1,51",

      sms1: "!rp,2306,0,data641003",
      sms2: "!rp,2306,1,data641003",
      sms3: "!R1,1024,35,255,1",
      sms4: "!R3,70,0",
      sms5: "!r0",
      sms6: "!R3,49,129",
      sms7: "!R3,70,0",
      sms8: "!R3,1,51",

      smsCounter: "0/8",

      changeConfigColor: "#999999",
      activatingSimColor: "#999999",
      settingUpDatabaseColor: "#999999",
      connectingSimAndDatabaseColor: "#999999",
      checkingPULSStatusColor: "#999999",
      addingInUsersAccountColor: "#999999",

      ApiError: "",
    };
  }

  async handleOnClickActivation() {
    console.log(this.props.ESN);
    console.log(this.props.ICCID);
    console.log(this.props.custCode);
    console.log(this.props.database);
    this.setState({ changeConfigColor: "#000000" });
    axios({
      method: "post",
      url:
        "http://activate.trackingsolutions.io:5000/api/SetConfig/" +
        this.state.ESN,
    }).then(async (response) => {
      console.log(response.data);
      if (response.data) {
        this.setState({ changeConfigColor: "Green" });
        this.setState({ activatingSimColor: "#000000" });
        axios({
          method: "get",
          url:
            "http://activate.trackingsolutions.io:5000/api/ActivateSim/" +
            this.state.ICCID +
            "/" +
            this.state.ESN +
            "/" +
            this.state.custCode +
            "/" +
            this.state.Name +
            "/" +
            this.state.eName +
            "/" +
            this.state.Make +
            "/" +
            this.state.Model +
            "/" +
            this.state.serialNumber +
            "/" +
            this.state.serviceInterval +
            "/" +
            this.state.lastServiceDate +
            "/" +
            this.state.hourMeter,
        }).then(async (response) => {
          console.log(response.data.data.status);
          if (response.data) {
            this.setState({ activatingSimColor: "Green" });
            this.setState({ connectingSimAndDatabaseColor: "#000000" });
            axios({
              method: "post",
              url:
                "http://activate.trackingsolutions.io:5000/api/SendSmsCommands/" +
                this.state.ICCID +
                "/" +
                this.state.sms1,
            }).then(async (response) => {
              console.log("sms1: ");
              console.log(response.data);
              this.setState({ smsCounter: "1/8" });
              if (response.data) {
                axios({
                  method: "post",
                  url:
                    "http://activate.trackingsolutions.io:5000/api/SendSmsCommands/" +
                    this.state.ICCID +
                    "/" +
                    this.state.sms2,
                }).then(async (response) => {
                  console.log("sms2: ");
                  console.log(response.data);
                  this.setState({ smsCounter: "2/8" });
                  if (response.data) {
                    axios({
                      method: "post",
                      url:
                        "http://activate.trackingsolutions.io:5000/api/SendSmsCommands/" +
                        this.state.ICCID +
                        "/" +
                        this.state.sms3,
                    }).then(async (response) => {
                      console.log("sms3: ");
                      console.log(response.data);
                      this.setState({ smsCounter: "3/8" });
                      if (response.data) {
                        axios({
                          method: "post",
                          url:
                            "http://activate.trackingsolutions.io:5000/api/SendSmsCommands/" +
                            this.state.ICCID +
                            "/" +
                            this.state.sms4,
                        }).then(async (response) => {
                          console.log("sms4: ");
                          console.log(response.data);
                          this.setState({ smsCounter: "4/8" });
                          if (response.data) {
                            axios({
                              method: "post",
                              url:
                                "http://activate.trackingsolutions.io:5000/api/SendSmsCommands/" +
                                this.state.ICCID +
                                "/" +
                                this.state.sms5,
                            }).then(async (response) => {
                              console.log("sms5: ");
                              console.log(response.data);
                              this.setState({ smsCounter: "5/8" });
                              if (response.data) {
                                axios({
                                  method: "post",
                                  url:
                                    "http://activate.trackingsolutions.io:5000/api/SendSmsCommands/" +
                                    this.state.ICCID +
                                    "/" +
                                    this.state.sms6,
                                }).then(async (response) => {
                                  console.log("sms6: ");
                                  console.log(response.data);
                                  this.setState({ smsCounter: "6/8" });
                                  if (response.data) {
                                    axios({
                                      method: "post",
                                      url:
                                        "http://activate.trackingsolutions.io:5000/api/SendSmsCommands/" +
                                        this.state.ICCID +
                                        "/" +
                                        this.state.sms7,
                                    }).then(async (response) => {
                                      console.log("sms7: ");
                                      console.log(response.data);
                                      this.setState({
                                        smsCounter: "7/8",
                                      });
                                      if (response.data) {
                                        axios({
                                          method: "post",
                                          url:
                                            "http://activate.trackingsolutions.io:5000/api/SendSmsCommands/" +
                                            this.state.ICCID +
                                            "/" +
                                            this.state.sms8,
                                        }).then(async (response) => {
                                          console.log("sms8: ");
                                          console.log(response.data);
                                          this.setState({
                                            smsCounter: "8/8",
                                          });
                                          if (response.data) {
                                            this.setState({
                                              connectingSimAndDatabaseColor:
                                                "Green",
                                            });

                                            this.setState({
                                              settingUpDatabaseColor: "#000000",
                                            });

                                            axios({
                                              method: "post",
                                              url:
                                                "http://activate.trackingsolutions.io:5000/api/connectToDatabase/" +
                                                this.state.ESN +
                                                "/" +
                                                this.state.custCode +
                                                "/" +
                                                this.state.database +
                                                "/" +
                                                this.state.Name +
                                                "/" +
                                                this.state.Make +
                                                "/" +
                                                this.state.Model +
                                                "/" +
                                                this.state.serialNumber +
                                                "/" +
                                                this.state.serviceInterval +
                                                "/" +
                                                this.state.lastServiceDate +
                                                "/" +
                                                this.state.hourMeter,
                                            }).then(async (response) => {
                                              console.log(response.data);
                                              if (response.data.data) {
                                                this.setState({
                                                  settingUpDatabaseColor:
                                                    "Green",
                                                });

                                                this.setState({
                                                  addingInUsersAccountColor:
                                                    "#000000",
                                                });
                                                axios({
                                                  url:
                                                    "http://activate.trackingsolutions.io:5000/api/addToStartupFleet/" +
                                                    this.state.ESN +
                                                    "/" +
                                                    this.state.custCode +
                                                    "/" +
                                                    this.state.database,
                                                }).then(async (response) => {
                                                  console.log("response.data");
                                                  if (response.data.data) {
                                                    console.log(response.data);
                                                    this.setState({
                                                      addingInUsersAccountColor:
                                                        "Green",
                                                    });

                                                    this.setState({
                                                      checkingPULSStatusColor:
                                                        "#000000",
                                                    });
                                                    axios({
                                                      method: "get",
                                                      url:
                                                        "http://activate.trackingsolutions.io:5000/api/CheckCofigStatus/" +
                                                        this.state.ESN +
                                                        "/" +
                                                        this.state.ICCID,
                                                    }).then(
                                                      async (response) => {
                                                        if (
                                                          response.data.data
                                                        ) {
                                                          console.log(
                                                            response.data.data
                                                          );
                                                          if (
                                                            response.data
                                                              .data ===
                                                            "Completed"
                                                          ) {
                                                            this.props.callbackActivationData(
                                                              "Active"
                                                            );
                                                            this.setState({
                                                              checkingPULSStatusColor:
                                                                "Green",
                                                            });
                                                          } else {
                                                            this.setState({
                                                              ApiError:
                                                                "Unable to Activate - please contact support@trackingsolutions.com.au",
                                                            });
                                                          }
                                                        } else if (
                                                          response.data.Error
                                                        ) {
                                                          this.setState({
                                                            checkingPULSStatusColor:
                                                              "Red",
                                                            ApiError:
                                                              "Unable to Activate - please contact support@trackingsolutions.com.au",
                                                          });
                                                        }
                                                      }
                                                    );
                                                  } else if (
                                                    response.data.Error
                                                  ) {
                                                    this.setState({
                                                      ApiError:
                                                        "Unable to Activate - please contact support@trackingsolutions.com.au",
                                                    });
                                                  }
                                                });
                                              } else if (response.data.Error) {
                                                this.setState({
                                                  ApiError:
                                                    "Unable to Activate - please contact support@trackingsolutions.com.au",
                                                });
                                              }
                                            });
                                          } else if (response.data.Error) {
                                            this.setState({
                                              ApiError:
                                                "Unable to Activate - please contact support@trackingsolutions.com.au",
                                            });
                                          }
                                        });
                                      } else if (response.data.Error) {
                                        this.setState({
                                          ApiError:
                                            "Unable to Activate - please contact support@trackingsolutions.com.au",
                                        });
                                      }
                                    });
                                  } else if (response.data.Error) {
                                    this.setState({
                                      ApiError:
                                        "Unable to Activate - please contact support@trackingsolutions.com.au",
                                    });
                                  }
                                });
                              } else if (response.data.Error) {
                                this.setState({
                                  ApiError:
                                    "Unable to Activate - please contact support@trackingsolutions.com.au",
                                });
                              }
                            });
                          } else if (response.data.Error) {
                            this.setState({
                              ApiError:
                                "Unable to Activate - please contact support@trackingsolutions.com.au",
                            });
                          }
                        });
                      } else if (response.data.Error) {
                        this.setState({
                          ApiError:
                            "Unable to Activate - please contact support@trackingsolutions.com.au",
                        });
                      }
                    });
                  } else if (response.data.Error) {
                    this.setState({
                      ApiError:
                        "Unable to Activate - please contact support@trackingsolutions.com.au",
                    });
                  }
                });
              } else if (response.data.Error) {
                this.setState({
                  ApiError:
                    "Unable to Activate - please contact support@trackingsolutions.com.au",
                });
              }
            });
          } else if (response.data.Error) {
            this.setState({
              ApiError:
                "Unable to Activate - please contact support@trackingsolutions.com.au",
            });
          }
        });
      } else if (response.data.Error) {
        this.setState({
          ApiError:
            "Unable to Activate - please contact support@trackingsolutions.com.au",
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleOnClickActivation.bind(this)}
          >
            Activate Device
          </Button>
          <Typography>
            <Box
              textAlign="left"
              fontSize="fontSize"
              fontStyle="italic"
              m={1}
              color={this.state.changeConfigColor}
            >
              {this.state.changeConfigColor === "Green"
                ? "Step 1. We're looking for your new device"
                : "Step 1. We're looking for your new device..."}
              {this.state.changeConfigColor === "#000000" ? <Loader /> : ""}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="left"
              fontSize="fontSize"
              fontStyle="italic"
              m={1}
              color={this.state.activatingSimColor}
            >
              {this.state.activatingSimColor === "Green"
                ? "Step 2. We're activating the SIM card"
                : "Step 2. We're activating the SIM card..."}
              {this.state.activatingSimColor === "#000000" ? <Loader /> : ""}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="left"
              fontSize="fontSize"
              fontStyle="italic"
              m={1}
              color={this.state.connectingSimAndDatabaseColor}
            >
              {this.state.connectingSimAndDatabaseColor === "Green"
                ? "Step 3. We're magically connecting them up to each other"
                : "Step 3. We're magically connecting them up to each other...  " +
                  this.state.smsCounter}
              {this.state.connectingSimAndDatabaseColor === "#000000" ? (
                <Loader />
              ) : (
                ""
              )}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="left"
              fontSize="fontSize"
              fontStyle="italic"
              m={1}
              color={this.state.settingUpDatabaseColor}
            >
              {this.state.settingUpDatabaseColor === "Green"
                ? "Step 4. It's the secret step. Believe us"
                : "Step 4. It's the secret step. Believe us..."}
              {this.state.settingUpDatabaseColor === "#000000" ? (
                <Loader />
              ) : (
                ""
              )}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="left"
              fontSize="fontSize"
              fontStyle="italic"
              m={1}
              color={this.state.addingInUsersAccountColor}
            >
              {this.state.addingInUsersAccountColor === "Green"
                ? "Step 5. Adding your new device to your account"
                : "Step 5. Adding your new device to your account..."}
              {this.state.addingInUsersAccountColor === "#000000" ? (
                <Loader />
              ) : (
                ""
              )}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="left"
              fontSize="fontSize"
              fontStyle="italic"
              m={1}
              color={this.state.checkingPULSStatusColor}
            >
              {this.state.checkingPULSStatusColor === "Green"
                ? "Step 6. Connecting your equipment to the web!"
                : "Step 6. Connecting your equipment to the web...!"}
              {this.state.checkingPULSStatusColor === "#000000" ? (
                <Loader />
              ) : (
                ""
              )}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="Center"
              fontSize="fontSize"
              fontStyle="italic"
              m={1}
              color="Blue"
            >
              {this.state.checkingPULSStatusColor === "Green"
                ? "The activation has been completed."
                : ""}
            </Box>
          </Typography>
          <div>
            <Typography>
              <Box textAlign="Center" fontSize="fontSize" m={1} color="red">
                {this.state.ApiError}
              </Box>
            </Typography>
          </div>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Activation);
