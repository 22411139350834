import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Esn from "../../img/esn.png";
import Sim from "../../img/sim.png";
import Help from "../../img/help.png";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";
import moment from "moment";

// import { getCurrentUserDateFormat } from "../components/HOC/GlobalTable/Table/TableFunction/dateFormating";
// import {
//   MuiPickersUtilsProvider,
//   InlineDatePicker,
// } from "@material-ui/pickers";

// import "../../img/style.css";
const styles = (theme) => ({
  layout: {
    width: "100%",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 0,
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3
      }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: "#fff",
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  imageAlign: {
    textAlign: "center",
  },
  submit: {
    // marginTop: theme.spacing.unit * 3,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "white",
    backgroundColor: "#F25B08",
    "&:hover": {
      backgroundColor: "#F25B08",
    },
  },
});

class ActivationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEsnHelp: false,
      openIccidHelp: false,
      scroll: "paper",
      numPages: null,
      errorTextEsn: "",
      errorTextIccid: "",
      errorTextsSerialNumber: "",

      // ESN: localStorage.getItem("localESN"),
      // Iccid: localStorage.getItem("localIccid"),
      // serialNumber: localStorage.getItem("localSerialNumber"),
      // Name: localStorage.getItem("localName"),
      // Make: localStorage.getItem("localMake"),
      // Model: localStorage.getItem("localModel"),
      // serviceInterval: localStorage.getItem("localServiceInterval"),
      // lastServiceDate: localStorage.getItem("localLastServiceDate"),
      // hourMeter: localStorage.getItem("localHourMeter"),

      ESN: localStorage.getItem("localESN")
        ? localStorage.getItem("localESN")
        : "",
      // : "4661424718",
      Iccid: localStorage.getItem("localIccid")
        ? localStorage.getItem("localIccid")
        : "",
      // : "8944502305189403626",
      serialNumber: localStorage.getItem("localSerialNumber")
        ? localStorage.getItem("localSerialNumber")
        : "",
      Name: localStorage.getItem("localName")
        ? localStorage.getItem("localName")
        : "",
      Make: localStorage.getItem("localMake")
        ? localStorage.getItem("localMake")
        : "-",
      Model: localStorage.getItem("localModel")
        ? localStorage.getItem("localModel")
        : "-",
      serviceInterval: localStorage.getItem("localServiceInterval")
        ? localStorage.getItem("localServiceInterval")
        : "0",
      lastServiceDate: localStorage.getItem("localLastServiceDate")
        ? localStorage.getItem("localLastServiceDate")
        : "",
      hourMeter: localStorage.getItem("localHourMeter")
        ? localStorage.getItem("localHourMeter")
        : "0",

      HideOtherInformation: true,
      hideICCIDField: true,
      hideCollectSIMdataButton: false,
      hideotherInfoButton: true,

      hideVerifyButton: true,
      verify: "",

      custCode: "",
      dataBase: "",

      // lastServiceDate: `${
      //   new Date().getFullYear() +
      //   "-" +
      //   0 +
      //   (new Date().getMonth() + 1) +
      //   "-" +
      //   new Date().getDate()
      // }`,
      // lastServiceDate: moment().format("YYYY-MM-DD"),
    };
    this.onLastServiceDateChange = this.onLastServiceDateChange.bind(this);
  }
  handleOnClickESNHelp = (scroll) => () => {
    this.setState({ openEsnHelp: true, scroll });
  };
  handleOnClickIccidHelp = (scroll) => () => {
    this.setState({ openIccidHelp: true, scroll });
  };
  handleClose = () => {
    this.setState({ openEsnHelp: false, openIccidHelp: false });
  };

  onChangeESN(esn) {
    localStorage.setItem("localESN", esn.target.value);
    this.setState({ ESN: esn.target.value });
  }
  onChangeIccid(iccid) {
    localStorage.setItem("localIccid", iccid.target.value);
    this.setState({ Iccid: iccid.target.value });
  }
  onChangeName(name) {
    localStorage.setItem("localName", name.target.value);
    this.setState({ Name: name.target.value });
  }
  onChangeMake(make) {
    localStorage.setItem("localMake", make.target.value);
    this.setState({ Make: make.target.value });
  }
  onChangeModel(model) {
    localStorage.setItem("localModel", model.target.value);
    this.setState({ Model: model.target.value });
  }
  onChangeSerialNumber(serialNumber) {
    localStorage.setItem("localSerialNumber", serialNumber.target.value);
    this.setState({ serialNumber: serialNumber.target.value });
  }
  onChangeServiceInterval(serviceInterval) {
    localStorage.setItem("localServiceInterval", serviceInterval.target.value);
    this.setState({ serviceInterval: serviceInterval.target.value });
  }
  onLastServiceDateChange(date) {
    // console.log(date);
    // const sdate = date ? moment(date).format("YYYY-MM-DD") : undefined;
    localStorage.setItem("localLastServiceDate", date.target.value);
    this.setState({ lastServiceDate: date.target.value });
    console.log(this.state.lastServiceDate);
  }
  onChangeHourMeter(hourMeter) {
    localStorage.setItem("localHourMeter", hourMeter.target.value);
    this.setState({ hourMeter: hourMeter.target.value });
  }

  componentDidMount() {
    // ESN: "4661424718",
    // Iccid: "8944502305189403626",
    // serialNumber: "",
    // Name: "",
    // Make: "NULL",
    // Model: "NULL",
    // serviceInterval: "0",
    // lastServiceDate: "",
    // hourMeter: "0",

    // localStorage.setItem("localESN", "4661424718");
    // localStorage.setItem("localIccid", "8944502305189403626");
    // localStorage.setItem("localSerialNumber", "");
    // localStorage.setItem("localName", "");
    // localStorage.setItem("localMake", "");
    // localStorage.setItem("localModel", "");
    // localStorage.setItem("localServiceInterval", "");
    // localStorage.setItem("localLastServiceDate", "");
    // localStorage.setItem("localHourMeter", "");

    let a = moment().format("YYYY-MM-DD");
    console.log(typeof a);
    console.log(typeof this.state.lastServiceDate);
  }

  handleOnClickAddOtherInformationNow() {
    this.setState({
      HideOtherInformation: false,
      hideVerifyButton: false,
      hideotherInfoButton: true,
    });
  }

  handleOnClickAddLater() {
    this.setState({
      HideOtherInformation: true,
      hideVerifyButton: false,
      hideotherInfoButton: true,
    });
  }

  handleOnClickCollectSIMdata() {
    console.log("CollectSIMdata");
    axios({
      method: "get",
      url:
        "http://activate.trackingsolutions.io:5000/api/CollectSIMdata/" +
        this.state.ESN,
    }).then(async (response) => {
      // console.log(response.data.error);
      // console.log(response.data.data[0].ICCID);

      if (response.data.error) {
        console.log("in error");
        console.log(response.data);
        this.setState({
          errorTextIccid: "Please enter",
          hideCollectSIMdataButton: true,
          hideICCIDField: false,
          hideotherInfoButton: false,
        });
      } else {
        console.log("in found data");
        console.log(response.data);
        if (response.data.data[0].ICCID) {
          console.log("in found data2");
          console.log(response.data);
          localStorage.setItem("localIccid", response.data.data[0].ICCID);
          this.setState({
            errorTextIccid: "",
            Iccid: response.data.data[0].ICCID,
            hideCollectSIMdataButton: true,
            hideotherInfoButton: false,
          });
          console.log("this.state.Iccid");
          console.log(this.state.Iccid);
        } else {
          console.log("in error2");
          console.log(response.data);
          this.setState({
            errorTextIccid: "Please enter",
            hideCollectSIMdataButton: true,
            hideICCIDField: false,
            hideotherInfoButton: false,
          });
        }
      }
    });
  }

  handleOnClickVerification() {
    //-------------------------------------------------------------------ESN Validation-------------------------------------------------------------------//
    console.log("IN click");
    this.setState({ verify: "Verifying..." });
    if (this.state.ESN.length === 0) {
      this.setState({
        errorTextEsn: "An ESN number of device is required",
        verify: "",
      });
    } else if (this.state.ESN.length !== 10) {
      this.setState({
        errorTextEsn: "Number must be 10 digits long",
        verify: "",
      });
    } else {
      console.log("here");
      axios({
        method: "get",
        url:
          "http://activate.trackingsolutions.io:5000/api/CheckESN/" +
          this.state.ESN,
      }).then(async (response) => {
        console.log("Here4");
        console.log(response.data.data.message); // Undefined, OK.
        if (response.data.data.message) {
          console.log("Here5");
          this.setState({
            errorTextEsn:
              "Device not found - please contact your administrator",
            verify: "",
          });
        } else {
          // let custCode = null;
          let dataBase = "vx_";
          if (response.data.data.group) {
            var custCodeArray = response.data.data.group.split("-");
            if (custCodeArray[0].length === 5) {
              console.log("--------" + custCodeArray[0].length);

              console.log(
                "-----" + dataBase.concat(custCodeArray[1].toLowerCase())
              );
              this.setState({
                custCode: custCodeArray[1].toLowerCase(),
                dataBase: dataBase.concat(custCodeArray[1].toLowerCase()),
              });

              var fourdigit = this.state.Iccid
                ? this.state.Iccid.substring(0, 4)
                : 0;
              console.log(fourdigit);
              if (this.state.Iccid.length === 0) {
                this.setState({
                  errorTextIccid: "An ICCID number of SIM is required",
                  verify: "",
                });
              } else if (this.state.Iccid.length !== 19) {
                this.setState({
                  errorTextIccid: "Number must be 19 digits long",
                  verify: "",
                });
              } else if (fourdigit === "8944" || fourdigit === "8961") {
                axios({
                  method: "get",
                  url:
                    "http://activate.trackingsolutions.io:5000/api/CheckICCID/" +
                    this.state.Iccid,
                }).then(async (response) => {
                  // console.log(response.data.data);
                  // console.log(response.data.data.message);
                  if (response.data.data.message) {
                    this.setState({
                      errorTextIccid:
                        "SIM not found - please contact your administrator",
                      verify: "",
                    });
                  } else {
                    this.setState({ errorTextIccid: "" });

                    if (this.state.serialNumber.length === 0) {
                      this.setState({
                        errorTextsSerialNumber:
                          "A serial number for equipment is required",
                        verify: "",
                      });
                    } else {
                      this.setState({ verify: "Verified" });
                      this.setState({ errorTextsSerialNumber: "" });

                      console.log(this.state.custCode);
                      console.log(this.state.dataBase);
                      console.log(this.state.ESN);
                      console.log(this.state.Iccid);
                      console.log(this.state.Name);
                      console.log(this.state.Make);
                      console.log(this.state.Model);
                      console.log(this.state.serialNumber);
                      console.log(this.state.serviceInterval);
                      console.log(this.state.lastServiceDate);
                      console.log(this.state.hourMeter);
                      this.props.callbackActivationFormData(
                        this.state.custCode,
                        this.state.dataBase,
                        this.state.ESN,
                        this.state.Iccid,
                        this.state.Name ? this.state.Name : this.state.ESN,
                        this.state.Name ? this.state.Name : "NULL",
                        this.state.Make,
                        this.state.Model,
                        this.state.serialNumber,
                        this.state.serviceInterval,
                        this.state.lastServiceDate,
                        this.state.hourMeter
                      );
                      this.props.goToNextStep();
                    }
                  }
                });
              } else {
                this.setState({
                  errorTextIccid:
                    "SIM not found - please contact your administrator.",
                  verify: "",
                });
              }

              console.log(dataBase);
            } else {
              if(response.data.data.group == "Sunbelt") {
                this.setState({
                  custCode: "sunbelt",
                  dataBase: dataBase.concat("sunbelt"),
                });

                var fourdigit = this.state.Iccid
                  ? this.state.Iccid.substring(0, 4)
                  : 0;
                console.log(fourdigit);
                if (this.state.Iccid.length === 0) {
                  this.setState({
                    errorTextIccid: "An ICCID number of SIM is required",
                    verify: "",
                  });
                } else if (this.state.Iccid.length !== 19) {
                  this.setState({
                    errorTextIccid: "Number must be 19 digits long",
                    verify: "",
                  });
                } else if (fourdigit === "8944" || fourdigit === "8961") {
                  axios({
                    method: "get",
                    url:
                      "http://activate.trackingsolutions.io:5000/api/CheckICCID/" +
                      this.state.Iccid,
                  }).then(async (response) => {
                    // console.log(response.data.data);
                    // console.log(response.data.data.message);
                    if (response.data.data.message) {
                      this.setState({
                        errorTextIccid:
                          "SIM not found - please contact your administrator",
                        verify: "",
                      });
                    } else {
                      this.setState({ errorTextIccid: "" });

                      if (this.state.serialNumber.length === 0) {
                        this.setState({
                          errorTextsSerialNumber:
                            "A serial number for equipment is required",
                          verify: "",
                        });
                      } else {
                        this.setState({ verify: "Verified" });
                        this.setState({ errorTextsSerialNumber: "" });

                        console.log(this.state.custCode);
                        console.log(this.state.dataBase);
                        console.log(this.state.ESN);
                        console.log(this.state.Iccid);
                        console.log(this.state.Name);
                        console.log(this.state.Make);
                        console.log(this.state.Model);
                        console.log(this.state.serialNumber);
                        console.log(this.state.serviceInterval);
                        console.log(this.state.lastServiceDate);
                        console.log(this.state.hourMeter);
                        this.props.callbackActivationFormData(
                          this.state.custCode,
                          this.state.dataBase,
                          this.state.ESN,
                          this.state.Iccid,
                          this.state.Name ? this.state.Name : this.state.ESN,
                          this.state.Name ? this.state.Name : "NULL",
                          this.state.Make,
                          this.state.Model,
                          this.state.serialNumber,
                          this.state.serviceInterval,
                          this.state.lastServiceDate,
                          this.state.hourMeter
                        );
                        this.props.goToNextStep();
                      }
                    }
                  });
                } else {
                  this.setState({
                    errorTextIccid:
                      "SIM not found - please contact your administrator.",
                    verify: "",
                  });
                }

                console.log(dataBase);
              } else {
                this.setState({
                  errorTextEsn:
                    "Device not found - please contact your administrator.",
                  verify: "",
                });
              }
            }
          }
          console.log(response.data.data.group);

          this.setState({
            // errorTextEsn: "",
            onChangeSim: "",
          });
        }
      });
    }

    //-------------------------------------------------------------------ICCID Validation-------------------------------------------------------------------//
    // var fourdigit = this.state.Iccid ? this.state.Iccid.substring(0, 4) : 0;
    // console.log(fourdigit);
    // if (this.state.Iccid.length === 0) {
    //   this.setState({
    //     errorTextIccid: "Required ICCID",
    //   });
    // } else if (this.state.Iccid.length !== 19) {
    //   this.setState({
    //     errorTextIccid: "19 digit ESN required",
    //   });
    // } else if (fourdigit === "8944" || fourdigit === "8961") {
    //   axios({
    //     method: "get",
    //     url: "http://activate.trackingsolutions.io:5000/api/CheckICCID/" + this.state.Iccid,
    //   }).then(async (response) => {
    //     // console.log(response.data.data);
    //     // console.log(response.data.data.message);
    //     if (response.data.data.message) {
    //       this.setState({
    //         errorTextIccid: "Iccid not found",
    //       });
    //     } else {
    //       this.setState({ errorTextIccid: "" });
    //     }
    //   });
    // } else {
    //   this.setState({ errorTextIccid: "Invalid Iccid" });
    // }

    //-------------------------------------------------------------------Serial number Validation-------------------------------------------------------------------//
    // if (this.state.serialNumber.length === 0) {
    //   this.setState({
    //     errorTextsSerialNumber: "Required Serial Number",
    //   });
    // } else {
    //   this.setState({ errorTextsSerialNumber: "" });
    // }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="h5">ESN & SIM Details</Typography>
            <form className={classes.form}>
              <div>
                <Grid container spacing={0} alignItems="flex-end">
                  <Grid item xs={11}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        required
                        id="esn"
                        name="esn"
                        label="ESN number of the new device."
                        type="number"
                        defaultValue={this.state.ESN}
                        fullWidth
                        autoComplete="off"
                        error={
                          this.state.errorTextEsn.length === 0 ? false : true
                        }
                        helperText={this.state.errorTextEsn}
                        onChange={this.onChangeESN.bind(this)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <div>
                      <Avatar
                        onClick={this.handleOnClickESNHelp("paper")}
                        className={classes.avatar}
                      >
                        <img src={Help} alt="Help" />
                      </Avatar>
                    </div>
                  </Grid>
                </Grid>

                <div>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      required
                      id="serialnumber"
                      name="serialnumber"
                      label="Serial number of the equipment you installed the unit into."
                      type="text"
                      defaultValue={this.state.serialNumber}
                      fullWidth
                      autoComplete="off"
                      error={
                        this.state.errorTextsSerialNumber.length === 0
                          ? false
                          : true
                      }
                      helperText={this.state.errorTextsSerialNumber}
                      onChange={this.onChangeSerialNumber.bind(this)}
                    />
                  </FormControl>
                </div>

                <div hidden={this.state.hideICCIDField}>
                  <Grid container spacing={0} alignItems="flex-end">
                    <Grid item xs={11}>
                      <FormControl margin="normal" required fullWidth>
                        <TextField
                          required
                          id="iccid"
                          name="iccid"
                          label="Serial of the SIM card you inserted into device."
                          type="number"
                          defaultValue={this.state.Iccid}
                          fullWidth
                          autoComplete="off"
                          error={
                            this.state.errorTextIccid.length === 0
                              ? false
                              : true
                          }
                          helperText={this.state.errorTextIccid}
                          onChange={this.onChangeIccid.bind(this)}
                        // onChange={this.props.onInputChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <div>
                        <Avatar
                          onClick={this.handleOnClickIccidHelp("paper")}
                          className={classes.avatar}
                        >
                          <img src={Help} alt="Help" />
                        </Avatar>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <div hidden={this.state.hideCollectSIMdataButton}>
                  <Button
                    style={{ marginRight: "5px" }}
                    fullWidth
                    variant="outlined"
                    color="primary"
                    className={classes.submit}
                    onClick={this.handleOnClickCollectSIMdata.bind(this)}
                  >
                    Autocheck the SIM card
                  </Button>
                </div>
                <br />
                <br />
                <div hidden={this.state.hideotherInfoButton}>
                  <Typography variant="body2">
                    Thanks, do you want to add other information about the
                    equipment or do this later?
                  </Typography>
                  <div>
                    <Grid container>
                      <Grid item xs={6}>
                        <Button
                          style={{ marginRight: "5px" }}
                          fullWidth
                          variant="outlined"
                          color="primary"
                          className={classes.submit}
                          onClick={this.handleOnClickAddOtherInformationNow.bind(
                            this
                          )}
                        >
                          Add Now
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      // style={{ margin: "1px", alignContent: "right" }}
                      >
                        <Button
                          style={{ marginLeft: "5px" }}
                          fullWidth
                          variant="outlined"
                          color="primary"
                          className={classes.submit}
                          onClick={this.handleOnClickAddLater.bind(this)}
                        >
                          Add Later
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div hidden={this.state.HideOtherInformation}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      id="vehicleName"
                      name="vehicleName"
                      label="Equipment name"
                      type="text"
                      fullWidth
                      autoComplete="off"
                      defaultValue={this.state.Name}
                      onChange={this.onChangeName.bind(this)}
                    />
                  </FormControl>

                  <FormControl margin="normal" fullWidth>
                    <TextField
                      id="make"
                      name="make"
                      label="Make"
                      type="text"
                      fullWidth
                      autoComplete="off"
                      defaultValue={this.state.Make}
                      onChange={this.onChangeMake.bind(this)}
                    />
                  </FormControl>

                  <FormControl margin="normal" fullWidth>
                    <TextField
                      id="model"
                      name="model"
                      label="Model"
                      type="text"
                      fullWidth
                      autoComplete="off"
                      defaultValue={this.state.Model}
                      onChange={this.onChangeModel.bind(this)}
                    />
                  </FormControl>

                  <FormControl margin="normal" fullWidth>
                    <TextField
                      id="serviceinterval"
                      name="serviceinterval"
                      label="Service interval"
                      type="number"
                      fullWidth
                      autoComplete="off"
                      defaultValue={this.state.serviceInterval}
                      onChange={this.onChangeServiceInterval.bind(this)}
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      label="Last service date"
                      type="date"
                      defaultValue={this.state.lastServiceDate}
                      onChange={this.onLastServiceDateChange}
                      format="yyyy/MM/dd"
                      disableFuture={true}
                    />
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <TextField
                      id="hourMeter"
                      name="hourMeter"
                      label="Hour meter reading"
                      type="number"
                      fullWidth
                      autoComplete="off"
                      defaultValue={this.state.hourMeter}
                      onChange={this.onChangeHourMeter.bind(this)}
                    />
                  </FormControl>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Typography>{this.state.verify}</Typography>
                </div>
              </div>

              <Dialog
                open={this.state.openEsnHelp}
                onClose={this.handleClose}
                scroll={this.state.scroll}
                aria-labelledby="scroll-dialog-title"
              >
                <DialogTitle id="scroll-dialog-title">
                  ESN/Hardware ID
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Paper className={classes.paper}>
                      <div className={classes.imageAlign}>
                        <img src={Esn} alt="Esn" style={{ width: "100%" }} />{" "}
                        <br />
                      </div>
                    </Paper>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="secondary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.openIccidHelp}
                onClose={this.handleClose}
                scroll={this.state.scroll}
                aria-labelledby="scroll-dialog-title"
              >
                <DialogTitle id="scroll-dialog-title">
                  SIM card serial number
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Paper className={classes.paper}>
                      <div className={classes.imageAlign}>
                        <img src={Sim} alt="Sim" style={{ width: "100%" }} />
                      </div>
                    </Paper>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="secondary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </Paper>
        </main>
      </React.Fragment>
    );
  }
}
ActivationForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ActivationForm);
