import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import axios from "axios";
import Loader from "../loader";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 0,
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3
      }px`,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "white",
    backgroundColor: "#F25B08",
    "&:hover": {
      backgroundColor: "#F25B08",
    },
  },
});

class VerifyActivation extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   // ESN: "4661429667",
    //   ESN: "4661412515",
    //   // ESN: this.props.ESN,
    //   // ICCID: "8944502305189403626",
    //   ICCID: this.props.Iccid,
    //   custCode: this.props.custCode,
    //   database: this.props.database,
    //   // custCode: "VXDEV",
    //   // database: "vx_dev",
    //   custCode: "CEA",
    //   database: "vx_cea",
    //   error: "",
    //   showIgnitionOn: false,
    //   checkIgnitionOn: "#999999",
    //   checkIgnitionOff: "#999999",
    // };

    this.state = {
      // ESN: "1263006630",
      // ESN: "4661412515",
      ESN: this.props.ESN,
      // ICCID: "8944502305189403626",
      ICCID: this.props.Iccid,
      custCode: this.props.custCode,
      database: this.props.database,
      // custCode: "VXDEV",
      // database: "vx_dev",
      // custCode: "CEA",
      // database: "vx_cea",
      error: "",
      foundEvent: "",
      showIgnitionOn: false,
      showIgnitionOff: false,
      active: false,
    };
  }

  chooseOnURL(length) {
    if (length === 10) {
      return "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOn/";
    } else if (length === 6) {
      return "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOnTS62/";
    }
  }

  chooseOffURL(length) {
    if (length === 10) {
      return "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOff/";
    } else if (length === 6) {
      return "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOffTS62/";
    }
  }

  getOnLat(length, data) {
    if (length === 10) {
      return data.LATITUDE ? data.LATITUDE : "";
    } else if (length === 6) {
      return data.latitude ? data.latitude : "";
    } else {
      return "";
    }
  }

  getOnLong(length, data) {
    if (length === 10) {
      return data.LONGITUDE ? data.LONGITUDE : "";
    } else if (length === 6) {
      return data.longitude ? data.longitude : "";
    } else {
      return "";
    }
  }

  handleOnClickVerifyActivation() {
    // componentDidMount() {
    // console.log("Yeeeeeeeeeeeeeeeee");
    console.log(this.props.ESN);

    let ONURL = this.chooseOnURL(this.props.ESN.length);
    let OFFURL = this.chooseOffURL(this.props.ESN.length);

    this.setState({
      showIgnitionOn: true,
      // showIgnitionOff: false,
      error: "",
      foundEvent: "",
    });
    axios({
      method: "get",
      url: ONURL + this.state.ESN + "/" + this.state.database,
      // url:
      //   "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOn/" +
      //   this.state.ESN +
      //   "/" +
      //   this.state.custCode +
      //   "/" +
      //   this.state.database,
    }).then(async (response) => {
      console.log("in responce");
      if (response.data) {
        console.log("got responce.data.ON");
        console.log(response.data);

        if (response.data.data !== "Time Out") {
          // Found ON
          console.log("Found ON");

          // console.log(response.data.data);
          // console.log(response.data.data.latitude);
          let onLat = this.getOnLat(this.props.ESN.length, response.data.data);
          let onLong = this.getOnLong(
            this.props.ESN.length,
            response.data.data
          );
          this.setState({
            showIgnitionOn: false,
            showIgnitionOff: true,
            error: onLat ? false : true,
            // error: false,
            foundEvent: "",
            network: "OK",
            hourMeterTracking: "OK",
            GPS: onLat ? "OK" : "Not tracking",
            latitude: onLat ? onLat : "",
            longitude: onLong ? onLong : "",
          });
          // this.props.callbackActivationVerificationData("Done");

          this.props.callbackActivationVerificationData(
            this.state.network,
            this.state.hourMeterTracking,
            this.state.GPS,
            this.state.latitude,
            this.state.longitude,
            this.state.error
          );
          axios({
            method: "get",
            url: OFFURL + this.state.ESN + "/" + this.state.database,
            // url:
            //   "http://activate.trackingsolutions.io:5000/api/CheckIgnitionOff/" +
            //   this.state.ESN +
            //   "/" +
            //   this.state.custCode +
            //   "/" +
            //   this.state.database,
          }).then(async (response) => {
            console.log("in responce OFF");
            if (response.data) {
              console.log("got responce OFF");
              if (response.data.data !== "Time Out") {
                console.log("got responce.data.off");
                // Found OFF
                console.log("found");
                this.setState({
                  network: "OK",
                  hourMeterTracking: "OK",
                  GPS: onLat ? "OK" : "Not tracking",
                  latitude: onLat ? onLat : "",
                  longitude: onLong ? onLong : "",
                  error: onLat ? false : true,

                  // error: false,
                  showIgnitionOff: false,
                  active: true,
                });
                this.props.callbackActivationVerificationData(
                  this.state.network,
                  this.state.hourMeterTracking,
                  this.state.GPS,
                  this.state.latitude,
                  this.state.longitude,
                  this.state.error
                );
                this.props.goToNextStep();
              } else if (response.data.data === "Time Out") {
                console.log("not Found Off");

                // Not Found OFF
                this.setState({
                  error: true,
                  showIgnitionOn: false,
                  network: "Not OK",
                  hourMeterTracking: "Not OK",
                });
                this.props.callbackActivationVerificationData(
                  this.state.network,
                  this.state.hourMeterTracking,
                  this.state.GPS,
                  this.state.latitude,
                  this.state.longitude,
                  this.state.error
                );
                this.props.goToNextStep();
              }
            } else {
              console.log("not Found responce.data.off");

              // Not Found OFF
              this.setState({
                error: true,
                showIgnitionOn: false,
                network: "Not OK",
                hourMeterTracking: "Not OK",
              });
              this.props.callbackActivationVerificationData(
                this.state.network,
                this.state.hourMeterTracking,
                this.state.GPS,
                this.state.latitude,
                this.state.longitude,
                this.state.error
              );
              this.props.goToNextStep();
            }
          });
        } else if (response.data.data === "Time Out") {
          console.log("Not Found ON");

          // Not found ON
          this.setState({
            network: "Not Found",
            hourMeterTracking: "Not tracking",
            // GPS: response.data.data.latitude ? "OK" : "Not tracking",
            // latitude: response.data.data.latitude
            //   ? response.data.data.latitude
            //   : "",
            // longitude: response.data.data.longitude
            //   ? response.data.data.longitude
            //   : "",
            GPS: "Not tracking",
            latitude: "",
            longitude: "",
            error: true,
            showIgnitionOn: false,
          });
          console.log("not found");
          this.props.callbackActivationVerificationData(
            this.state.network,
            this.state.hourMeterTracking,
            this.state.GPS,
            this.state.latitude,
            this.state.longitude,
            this.state.error
          );
          this.props.goToNextStep();
        }
      } else {
        console.log("Not Found responce.data.ON");

        // Not Found OFF
        this.setState({
          network: "Not Found",
          hourMeterTracking: "Not tracking",
          // GPS: response.data.data.latitude ? "OK" : "Not tracking",
          // latitude: response.data.data.latitude
          //   ? response.data.data.latitude
          //   : "",
          // longitude: response.data.data.longitude
          //   ? response.data.data.longitude
          //   : "",
          GPS: "Not tracking",
          latitude: "",
          longitude: "",
          error: true,
          showIgnitionOn: false,
        });
        console.log("not found");
        this.props.callbackActivationVerificationData(
          this.state.network,
          this.state.hourMeterTracking,
          this.state.GPS,
          this.state.latitude,
          this.state.longitude,
          this.state.error
        );
        this.props.goToNextStep();
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={this.handleOnClickVerifyActivation.bind(this)}
            className={classes.button}
          >
            Verify Installation
          </Button>
          {this.state.showIgnitionOn && (
            <div>
              <Typography variant="">
                Please <b style={{ color: "Green" }}>RESTART</b> the vehicle.
              </Typography>
              <Typography variant="body2">Looking for events...</Typography>
              <Loader />
              <br />
              <br />
            </div>
          )}

          {this.state.showIgnitionOff && (
            <div>
              <Typography variant="">
                Now, turn <b style={{ color: "Red" }}>OFF</b> the vehicle.
              </Typography>
              <Typography variant="body2">Checking the events...</Typography>
              <Loader />
              <br />
            </div>
          )}

          {this.state.error && (
            <div>
              <Typography>
                <Box textAlign="left" fontSize={12} m={1} color="Red">
                  {
                    "Unable to find events - please contact support@trackingsolutions.com.au"
                  }
                </Box>
              </Typography>
            </div>
          )}

          {this.state.active && (
            <div>
              <Typography>
                <Box textAlign="left" fontSize={15} m={1} color="Green">
                  {"The installation is complete and the device is now active."}
                </Box>
              </Typography>
            </div>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(VerifyActivation);
