import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Esn from "../../img/connectionLED.png";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

class powerConnection extends Component {
  render() {
    function createData(network, networkCondition) {
      return { network, networkCondition };
    }

    const networkTableRows = [
      createData("Off", "Device is off or in sleep mode"),
      createData("Slow Blinking", "Device is searching for network"),
      createData("Fast Blinking", "Network is available"),
      createData(
        "Alternates from slow to fast blinking once per second",
        "Network is connected but waiting for acknowledgement from servers"
      ),
      createData(
        "On solid",
        "(Good) Connected to network and receiving messages"
      ),
    ];

    const gpsTableRows = [
      createData("Off", "GPS is off"),
      createData("Slow Blinking", "GPS is on"),
      createData("Fast Blinking", "GPS Time Sync"),
      createData("On solid", "(Good) GPS fix"),
    ];
    return (
      <div>
        <Typography variant="body2">
          Make sure both LED lights on the device are solid.
        </Typography>
        <br />
        <img src={Esn} alt="Esn" style={{ width: "100%" }} />
        <br />

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <b>Network LED (ORANGE)</b>
                </TableCell>
                <TableCell align="left">
                  <b>Condition</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {networkTableRows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.network}</TableCell>
                  <TableCell align="left">{row.networkCondition}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <b>GPS LED (GREEN)</b>
                </TableCell>
                <TableCell align="left">
                  <b>Condition</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gpsTableRows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.network}</TableCell>
                  <TableCell align="left">{row.networkCondition}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default powerConnection;
