import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  avatar: {
    backgroundColor: "#ffffff",
  },
});

class beforeYouStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openEsnHelp: false,
      scroll: "paper",
      numPages: null,
    };
  }
  handleOnClickESNHelp = (scroll) => () => {
    this.setState({ openEsnHelp: true, scroll });
  };
  handleClose = () => {
    this.setState({ openEsnHelp: false, openIccidHelp: false });
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="">
          To successfully check your installation please ensure the vehicle is
          in an open space and is turned off.
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(beforeYouStart);
