import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Help from "../../img/help.png";
import Help_blank from "../../img/help_blank.png";
import connection from "../../img/connectionLED.png";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Esn from "../../img/esn.png";
import Sim from "../../img/sim.png";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PowerConnection from "./powerConnection";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  avatar: {
    backgroundColor: "#ffffff",
  },
});

class beforeYouStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openEsnHelp: false,
      openIccidHelp: false,
      openconnectionHelp: false,

      scroll: "paper",
      numPages: null,
    };
  }
  handleOnClickESNHelp = (scroll) => () => {
    this.setState({ openEsnHelp: true, scroll });
  };
  handleOnClickIccidHelp = (scroll) => () => {
    this.setState({ openIccidHelp: true, scroll });
  };
  handleOnClickconnectionHelp = (scroll) => () => {
    this.setState({ openconnectionHelp: true, scroll });
  };
  handleClose = () => {
    this.setState({
      openEsnHelp: false,
      openIccidHelp: false,
      openconnectionHelp: false,
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {/* <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body2">
              {" "}
              <b>Power Connection</b>{" "}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {" "}
            <PowerConnection />{" "}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <br /> */}
        {/* <Typography variant="body2">
          To successfully activate your device we will need three (3) key pieces
          of information.
        </Typography>
        <br /> */}
        <div>
          <Typography variant="subtitle2">
            <b>Confirm the device is ready to connect</b>
          </Typography>
          <Typography variant="body2">
            1. Ensure the vehicle is in an open space and is turned on.
          </Typography>
          {/* <Typography variant="body2">2. Check the light status:</Typography> */}
          <Grid container spacing={0} alignItems="flex-end">
            <Grid item xs={11}>
              <Typography variant="body2">
                2. Check the light status:
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <div>
                <Avatar
                  onClick={this.handleOnClickconnectionHelp("paper")}
                  className={classes.avatar}
                >
                  <img src={Help} alt="Help" />
                </Avatar>
              </div>
            </Grid>
          </Grid>
          <Typography variant="body2">
            &nbsp; &nbsp; 1. The green light (GPS status) should be solid. Note
            that this may take a couple of minutes.
          </Typography>
          <Typography variant="body2">
            &nbsp; &nbsp; 2. The orange light (Network availability) should be
            either: solid, fast blinking, alternating between fast and slow
            blinking. Note that this may take a couple of minutes.
          </Typography>
        </div>
        <br />
        <div>
          <Typography variant="subtitle2">
            <b>Have the details ready</b>
          </Typography>

          <Grid container spacing={0} alignItems="flex-end">
            <Grid item xs={11}>
              <Typography variant="body2">
                1.The ESN number of the hardware device. It will be on the back
                of the device as per the picture.
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <div>
                <Avatar
                  onClick={this.handleOnClickESNHelp("paper")}
                  className={classes.avatar}
                >
                  <img src={Help} alt="Help" />
                </Avatar>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={0} alignItems="flex-end">
            <Grid item xs={11}>
              <Typography variant="body2">
                2. The serial number of the SIM card you inserted into the
                device. It will be at the bottom of the card as per the picture.
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <div>
                <Avatar
                  onClick={this.handleOnClickIccidHelp("paper")}
                  className={classes.avatar}
                >
                  <img src={Help} alt="Help" />
                </Avatar>
              </div>
            </Grid>
          </Grid>

          {/* <Typography variant="body2">
            2. The ICC-ID number of the SIM you inserted into the device. It
            will be at the bottom of the card.
          </Typography> */}

          <Grid container spacing={0} alignItems="flex-end">
            <Grid item xs={11}>
              <Typography variant="body2">
                3. The serial number of the equipment you installed the device
                in. This will be provided by your business.
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <div>
                <Avatar
                  onClick={this.handleOnClickIccidHelp("paper")}
                  className={classes.avatar}
                >
                  <img src={Help_blank} alt="Help_blank" />
                </Avatar>
              </div>
            </Grid>
          </Grid>

          {/* <Typography variant="body2">
            3. The serial number of the equipment you installed the device in.
            This will be provided by your business.
          </Typography> */}
          <br />
          <Typography variant="subtitle2">
            <b>
              (Optional) There is some other valuable information we'd like
              about the equipment the unit is installed in, but you can provide
              this at a later date.
            </b>
          </Typography>
          <Typography variant="body2">
            - The equipment's name or ID used by your business
          </Typography>
          <Typography variant="body2">- Equipment make</Typography>
          <Typography variant="body2">- Equipment model</Typography>
          <Typography variant="body2">- Service interval for PMs</Typography>
          <Typography variant="body2">- Date of its last service</Typography>
          <Typography variant="body2">
            - hour meter reading at time of install
          </Typography>
        </div>

        <br />
        <Typography variant="body2">Ok, let's start the process.</Typography>
        <Dialog
          open={this.state.openEsnHelp}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">ESN/Hardware ID</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Paper className={classes.paper}>
                <div className={classes.imageAlign}>
                  <img src={Esn} alt="Esn" style={{ width: "100%" }} /> <br />
                </div>
              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openIccidHelp}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            SIM card serial number
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Paper className={classes.paper}>
                <div className={classes.imageAlign}>
                  <img src={Sim} alt="Sim" style={{ width: "100%" }} />
                </div>
              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openconnectionHelp}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Connections</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Paper className={classes.paper}>
                <div className={classes.imageAlign}>
                  <img src={connection} alt="Sim" style={{ width: "100%" }} />
                </div>
              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(beforeYouStart);
