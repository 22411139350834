import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import axios from "axios";

// activate.trackingsolutions.io
// activate.trackingsolutions.io
const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 0,
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3
      }px`,
  },
});

class VerificationCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ESN: "4661424718",
      //   4661431404
      // 4661400906    CEA
      // 4661409335
      ESN: this.props.ESN,
      //ICCID: "8944502305189403626",
      serialNumber: this.props.serialNumber,
      LastCommunication: "",
      LastGPSCommunication: "",
      GPSLocationLat: "",
      GPSLocationLong: "",
      BatteryVoltage: "",
      hideLoader: false,
      fontSize: 15,
      error: this.props.error,
      network: this.props.network,
      hourMeterTracking: this.props.hourMeterTracking,
      GPS: this.props.GPS,
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      custCode: this.props.custCode,
      mapLocation:
        "https://www.google.com/maps/search/?api=1&query=" +
        this.props.latitude +
        "," +
        this.props.longitude,
    };
  }

  componentDidMount() {
    let esn = this.state.ESN ? this.state.ESN : "-";
    let network = this.state.network ? this.state.network : "-";
    let hourMeterTracking = this.state.hourMeterTracking ? this.state.hourMeterTracking : "-";
    let gps = this.state.GPS ? this.state.GPS : "-";
    let custCode = this.state.custCode ? this.state.custCode : "-";
    let serialNumber = this.state.serialNumber ? this.state.serialNumber : "-";
    axios({
      method: "get",
      url:
        "http://activate.trackingsolutions.io:5000/api/SendCheckEmail/" +
        esn +
        "/" +
        network +
        "/" +
        hourMeterTracking +
        "/" +
        gps +
        "/" +
        custCode +
        "/" +
        serialNumber
    }).then(async (response) => {
      console.log("CheckEmail sent !");
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Typography>
            <Box
              textAlign="left"
              fontSize={this.state.fontSize}
              m={1}
              color={this.state.network === "OK" ? "Green" : "Red"}
            >
              Network : {this.state.network}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="left"
              fontSize={this.state.fontSize}
              m={1}
              color={this.state.hourMeterTracking === "OK" ? "Green" : "Red"}
            >
              Hour meter tracking : {this.state.hourMeterTracking}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="left"
              fontSize={this.state.fontSize}
              m={1}
              color={this.state.GPS === "OK" ? "Green" : "Red"}
            >
              GPS : {this.state.GPS}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="left"
              fontSize={this.state.fontSize}
              m={1}
              color="Green"
            >
              {this.state.latitude ? "Latitude : " : ""}
              {this.state.latitude ? this.state.latitude : ""}
              {/* Latitude : {this.state.latitude} */}
            </Box>
          </Typography>
          <Typography>
            <Box
              textAlign="left"
              fontSize={this.state.fontSize}
              m={1}
              color="Green"
            >
              {this.state.longitude ? "Longitude : " : ""}
              {this.state.longitude ? this.state.longitude : ""}
              {/* Longitude : {this.state.longitude} */}
            </Box>
          </Typography>
          <Typography variant="body2">
            <Box textAlign="left" fontSize={13} m={1}>
              <a
                href={this.state.mapLocation}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.latitude ? "View Map" : ""}
              </a>
            </Box>
          </Typography>
          {this.state.error && (
            <div>
              <Typography>
                <Box textAlign="left" fontSize={12} m={1} color="Balck">
                  {
                    "If any of the results above are red, please check your install and repeat the process. "
                  }
                </Box>
                <Box textAlign="left" fontSize={12} m={1} color="Balck">
                  {
                    "If after checking they continue to be red please call 1300 725 713 or email support on support@trackingsolutions.com.au"
                  }
                </Box>
              </Typography>
            </div>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(VerificationCompleted);
