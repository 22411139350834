import React, { Component } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import BeforeYouStart from "./Components/BeforeYouStart/beforeYouStart";
import ActivationForm from "./Components/ActivationForm/activationForm";
import Activation from "./Components/Activation/activation";
import VerifyActivation from "./Components/VerifyActivation/verifyActivation";
import Results from "./Components/CheckUnitInstallation/Results";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import axios from "axios";

const styles = (theme) => ({
  root: {
    width: "50%",
    display: "block",
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  root2: {
    width: "100%",
    display: "block",
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "white",
    backgroundColor: "#F25B08",
    "&:hover": {
      backgroundColor: "#F25B08",
    },
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

  step: {
    "& $completed": {
      color: "#F25B08",
    },
    "& $active": {
      color: "#F25B08",
    },
  },
  active: {},
  completed: {},
});

function getSteps() {
  return [
    "Before you start",
    "Activation Form",
    "Activation",
    "Verify Installation",
    "Results",
  ];
}

class USApp extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      activeStep: 0,
      Esn: "",
      Iccid: "",
      Name: "",
      eName: "",
      Make: "",
      Model: "",
      serialNumber: "",
      serviceInterval: "",
      lastServiceDate: "",
      hourMeter: "",
      CustCode: "",
      DataBase: "",
      Next: false,
      Back: false,
      network: "",
      hourMeterTracking: "",
      GPS: "",
      latitude: "",
      longitude: "",
      error: "",
    };
  }

  componentDidMount() {
    localStorage.clear();
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();

    const handleNext = () => {
      console.log("Active" + this.state.activeStep);
      // localStorage.setItem("isValid", "No");
      if (this.state.activeStep === 1) {
        this.child.current.handleOnClickVerification();
        // if (localStorage.getItem("isValid") === "Yes") {
      } else if (this.state.activeStep === 3) {
        localStorage.clear();
        axios({
          method: "post",
          url:
            "http://activate.trackingsolutions.io:5000/api/skipEngineCheck/"
        })
        this.setState({ activeStep: this.state.activeStep + 1 });
        this.setState({ Next: false });
      } else {
        this.setState({ activeStep: this.state.activeStep + 1 });
        this.setState({ Next: false });
      }
    };

    const handleBack = () => {
      console.log(this.state.activeStep);
      this.setState({ activeStep: this.state.activeStep - 1 });
      this.setState({ Next: false });
    };

    const goToNextStep = () => {
      this.setState({ activeStep: this.state.activeStep + 1 });
      this.setState({ Next: true });
      // this.setState({ Next: false });
    };

    const handleReset = () => {
      this.setState({ activeStep: 0, Next: false });
    };

    const getStepContent = (step) => {
      const callbackActivationFormDataFunction = (
        Custcode,
        Database,
        ESN,
        ICCID,
        Name,
        eName,
        Make,
        Model,
        serialNumber,
        serviceInterval,
        lastServiceDate,
        hourMeter
      ) => {
        console.log(Custcode);
        console.log(Database);
        console.log(ESN);
        console.log(ICCID);
        console.log(Name);
        console.log(eName);
        console.log(Make);
        console.log(Model);
        console.log(serialNumber);
        console.log(serviceInterval);
        console.log(lastServiceDate);
        console.log(hourMeter);
        if (ESN && ICCID) {
          this.setState({ Next: false });
        }
        this.setState({ CustCode: Custcode });
        this.setState({ DataBase: Database });
        this.setState({ Esn: ESN });
        this.setState({ Iccid: ICCID });
        this.setState({ Name: Name });
        this.setState({ eName: eName });
        this.setState({ Make: Make });
        this.setState({ Model: Model });
        this.setState({ serialNumber: serialNumber });
        this.setState({ serviceInterval: serviceInterval });
        this.setState({ lastServiceDate: lastServiceDate });
        this.setState({ hourMeter: hourMeter });
      };

      const callbackActivationDataFunction = (message) => {
        console.log(message);
        if (message) {
          this.setState({ Next: false });
        }
      };

      const callbackActivationVerificationDataFunction = (
        network,
        hourMeterTracking,
        GPS,
        latitude,
        longitude,
        error
      ) => {
        console.log(network);
        console.log(hourMeterTracking);
        console.log(GPS);
        console.log(latitude);
        console.log(longitude);
        console.log(error);
        this.setState({
          network: network,
          hourMeterTracking: hourMeterTracking,
          GPS: GPS,
          latitude: latitude,
          longitude: longitude,
          error: error,
        });
      };

      switch (step) {
        case 0:
          return <BeforeYouStart />;
        case 1:
          return (
            <ActivationForm
              callbackActivationFormData={callbackActivationFormDataFunction}
              ref={this.child}
              goToNextStep={goToNextStep}
            />
          );
        case 2:
          return (
            <Activation
              custCode={this.state.CustCode}
              database={this.state.DataBase}
              ESN={this.state.Esn}
              ICCID={this.state.Iccid}
              Name={this.state.Name}
              eName={this.state.eName}
              Make={this.state.Make}
              Model={this.state.Model}
              serialNumber={this.state.serialNumber}
              serviceInterval={this.state.serviceInterval}
              lastServiceDate={this.state.lastServiceDate}
              hourMeter={this.state.hourMeter}
              callbackActivationData={callbackActivationDataFunction}
            />
          );
        case 3:
          return (
            <VerifyActivation
              custCode={this.state.CustCode}
              database={this.state.DataBase}
              ESN={this.state.Esn}
              ICCID={this.state.Iccid}
              callbackActivationVerificationData={
                callbackActivationVerificationDataFunction
              }
              goToNextStep={goToNextStep}
            />
          );
        case 4:
          return (
            <Results
              ESN={this.state.Esn}
              serialNumber={this.state.serialNumber}
              network={this.state.network}
              hourMeterTracking={this.state.hourMeterTracking}
              GPS={this.state.GPS}
              latitude={this.state.latitude}
              longitude={this.state.longitude}
              error={this.state.error}
              custCode={this.state.CustCode}
            />
          );
        default:
          return "Unknown step";
      }
    };

    const buttonName = () => {
      if (this.state.activeStep === steps.length - 1) {
        console.log("Finish")
        return "Finish"
      }
      else if (this.state.activeStep === steps.length - 2) {
        console.log("Skip")

        return "Skip"
      }
      else {
        console.log("Next")
        return "Next"
      }
    }

    return (
      <div style={{ background: "white" }}>
        {/* <Layout /> */}
        <Typography variant="h6" style={{ textAlign: "center" }}>
          <b>Activate Your Unit</b>
        </Typography>
        <Hidden xsDown>
          <div className={classes.root}>
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step
                  key={label}
                  classes={{
                    root: classes.step,
                    completed: classes.completed,
                    active: classes.active,
                    // disabled: classes.disabled,
                  }}
                >
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                        disabled: classes.disabled,
                      },
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {label}
                    </Typography>
                    {/* <b></b> */}
                  </StepLabel>
                  <StepContent>
                    <Typography>{getStepContent(index)}</Typography>
                    <div className={classes.actionsContainer}>
                      <div>
                        <dev hidden={this.state.activeStep === 0}>
                          <Button
                            disabled={this.state.activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                        </dev>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                          disabled={this.state.Next}
                        >
                          {/* {this.state.activeStep === steps.length - 1
                            ? "Finish"
                            : "Next"} */}
                          {buttonName()}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {this.state.activeStep === steps.length && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>All steps completed </Typography>
                <Button
                  variant="contained"
                  color="#FF5A4E"
                  onClick={handleReset}
                  className={classes.button}
                >
                  Activate another device
                </Button>
              </Paper>
            )}
          </div>
        </Hidden>
        <Hidden smUp>
          <div className={classes.root2}>
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step
                  key={label}
                  classes={{
                    root: classes.step,
                    completed: classes.completed,
                    active: classes.active,
                    // disabled: classes.disabled,
                  }}
                >
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                        disabled: classes.disabled,
                      },
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {label}
                    </Typography>
                    {/* <b></b> */}
                  </StepLabel>
                  <StepContent>
                    <Typography>{getStepContent(index)}</Typography>
                    <div className={classes.actionsContainer}>
                      <div>
                        <dev hidden={this.state.activeStep === 0}>
                          <Button
                            disabled={this.state.activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                        </dev>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                          disabled={this.state.Next}
                        >
                          {/* {this.state.activeStep === steps.length - 1
                            ? "Finish"
                            : "Next"} */}
                          {buttonName()}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {this.state.activeStep === steps.length && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>All steps completed </Typography>
                <Button
                  variant="contained"
                  color="#FF5A4E"
                  onClick={handleReset}
                  className={classes.button}
                >
                  Activate another device
                </Button>
              </Paper>
            )}
          </div>
        </Hidden>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default withStyles(styles)(USApp);
