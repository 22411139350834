import React, { Component } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BeforeYouStart from "./Components/CheckUnitInstallation/beforeYouStartAu";
import EnterDetails from "./Components/CheckUnitInstallation/EnterDetails";
import CheckInstall from "./Components/CheckUnitInstallation/checkInstall";
import Results from "./Components/CheckUnitInstallation/Results";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import axios from "axios";

const styles = (theme) => ({
  root: {
    width: "50%",
    display: "block",
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  root2: {
    width: "100%",
    display: "block",
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "white",
    backgroundColor: "#F25B08",
    "&:hover": {
      backgroundColor: "#F25B08",
    },
  },

  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

  step: {
    "& $completed": {
      color: "#F25B08",
    },
    "& $active": {
      color: "#F25B08",
    },
  },
  active: {},
  completed: {},
});

function getSteps() {
  return ["Before you start", "Enter details", "Check install", "Results"];
}

class AUApp extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      activeStep: 0,
      Esn: "",
      Iccid: "",
      Name: "",
      Make: "",
      Model: "",
      serialNumber: "",
      serviceInterval: "",
      lastServiceDate: "",
      hourMeter: "",
      custCode: "",
      DataBase: "",
      Next: false,
      Back: false,
      network: "",
      hourMeterTracking: "",
      trackedHourMeter: "",
      GPS: "",
      latitude: "",
      longitude: "",
      error: "",
    };
  }

  componentDidMount() {
    localStorage.clear();
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();

    const handleNext = () => {
      console.log("Active" + this.state.activeStep);
      if (this.state.activeStep === 1) {
        this.child.current.handleOnClickVerification();
      } else {
        this.setState({ activeStep: this.state.activeStep + 1 });
        this.setState({ Next: false });
        if (this.state.activeStep === steps.length - 2) {
          this.setState({ network: 'Skipped' });
          this.setState({ hourMeterTracking: 'Skipped' });
          this.setState({ GPS: 'Skipped' });
          axios({
            method: "post",
            url:
              "http://activate.trackingsolutions.io:5000/api/skipEngineCheck/"
          })
        }
      }
    };
    const handleBack = () => {
      console.log(this.state.activeStep);
      this.setState({ activeStep: this.state.activeStep - 1 });
      this.setState({ Next: false });
      if (this.state.activeStep === 2) {
        axios({
          method: "post",
          url:
            "http://activate.trackingsolutions.io:5000/api/skipEngineCheck/"
        })
      }
    };

    const goToNextStep = () => {
      this.setState({ activeStep: this.state.activeStep + 1 });
      this.setState({ Next: false });
    };

    const handleReset = () => {
      this.setState({ activeStep: 0, Next: false });
    };

    const getStepContent = (step) => {
      const callbackEnterDetailsFunction = (ESN, serialNumber, database, Ename, hourMeter, custCode) => {
        console.log(ESN);
        console.log(serialNumber);
        console.log(database);
        console.log(Ename);
        console.log(hourMeter);
        this.setState({ Esn: ESN });
        this.setState({ serialNumber: serialNumber });
        this.setState({ DataBase: database });
        this.setState({ Name: Ename });
        this.setState({ trackedHourMeter: hourMeter });
        this.setState({ custCode: custCode });
      };

      const callbackCheckInstallFunction = (
        network,
        hourMeterTracking,
        GPS,
        latitude,
        longitude,
        error
      ) => {
        console.log(network);
        console.log(hourMeterTracking);
        console.log(GPS);
        console.log(latitude);
        console.log(longitude);
        console.log(error);
        this.setState({
          network: network,
          hourMeterTracking: hourMeterTracking,
          GPS: GPS,
          latitude: latitude,
          longitude: longitude,
          error: error,
        });


      };

      switch (step) {
        case 0:
          return <BeforeYouStart />;
        case 1:
          return (
            <EnterDetails
              callbackEnterDetails={callbackEnterDetailsFunction}
              ref={this.child}
              goToNextStep={goToNextStep}
            />
          );
        case 2:
          return (
            <CheckInstall
              ESN={this.state.Esn}
              serialNumber={this.state.serialNumber}
              database={this.state.DataBase}
              goToNextStep={goToNextStep}
              callbackCheckInstall={callbackCheckInstallFunction}
            />
          );
        case 3:
          return (
            <Results
              ESN={this.state.Esn}
              serialNumber={this.state.serialNumber}
              network={this.state.network}
              hourMeterTracking={this.state.hourMeterTracking}
              GPS={this.state.GPS}
              latitude={this.state.latitude}
              longitude={this.state.longitude}
              error={this.state.error}
              custCode={this.state.custCode}
            />
          );
        default:
          return "Unknown step";
      }
    };

    const buttonName = () => {
      if (this.state.activeStep === steps.length - 1) {
        console.log("Finish")
        return "Finish"
      }
      else if (this.state.activeStep === steps.length - 2) {
        console.log("Skip")

        return "Skip"
      }
      else {
        console.log("Next")
        return "Next"
      }
    }

    return (
      <div style={{ background: "white" }}>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          <b>Check Unit Installation</b>
        </Typography>
        <Hidden xsDown>
          <div className={classes.root}>
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step
                  key={label}
                  classes={{
                    root: classes.step,
                    completed: classes.completed,
                    active: classes.active,
                    // disabled: classes.disabled,
                  }}
                >
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                        disabled: classes.disabled,
                      },
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {label}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography>{getStepContent(index)}</Typography>
                    <div className={classes.actionsContainer}>
                      <div>
                        <dev hidden={this.state.activeStep === 0}>
                          <Button
                            disabled={this.state.activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                        </dev>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                          disabled={this.state.Next}
                        >
                          {buttonName()}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {this.state.activeStep === steps.length && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleReset}
                className={classes.button}
              >
                Check another device
              </Button>
            )}
          </div>
        </Hidden>
        <Hidden smUp>
          <div className={classes.root2}>
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step
                  key={label}
                  classes={{
                    root: classes.step,
                    completed: classes.completed,
                    active: classes.active,
                    // disabled: classes.disabled,
                  }}
                >
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                        disabled: classes.disabled,
                      },
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {label}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography>{getStepContent(index)}</Typography>
                    <div className={classes.actionsContainer}>
                      <div>
                        <dev hidden={this.state.activeStep === 0}>
                          <Button
                            disabled={this.state.activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                        </dev>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                          disabled={this.state.Next}
                        >
                          {buttonName()}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {this.state.activeStep === steps.length && (
              <Button
                variant="contained"
                color="#FF5A4E"
                onClick={handleReset}
                className={classes.button}
              >
                Check another device
              </Button>
            )}
          </div>
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(AUApp);
